import {
	AfterViewInit,
	Component,
	Input,
	OnChanges,
	SimpleChanges
} from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ShortNumberPipe } from '../../shared/pipes/shortnumber-pipe';
import { GenericChartDataModel } from './models/chart.model';

@Component({
	template: ``,
	standalone: true
})
export class AppChart implements OnChanges, AfterViewInit {
	constructor(protected shortNumberPipe: ShortNumberPipe) {}

	protected initHappened = false;
	@Input() public chartData: GenericChartDataModel;
	@Input() tooltip: (tooltipItem: TooltipItem<'doughnut'>) => string = (
		tooltipItem: TooltipItem<'doughnut'>
	) => {
		return `${this.shortNumberPipe.transform(tooltipItem.parsed)}%`;
	};

	ngOnChanges(changes: SimpleChanges) {
		const change = changes['chartData'];

		if (change && change.currentValue && this.initHappened) {
			this.generateChart(change.currentValue);
		}
	}

	ngAfterViewInit() {
		this.initHappened = true;
		this.generateChart(this.chartData);
	}

	generateChart(chartData: GenericChartDataModel) {}
}
