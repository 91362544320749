<ng-container>
	<div
		class="action-button-2 cursor-pointer {{ disabled ? 'disabled' : '' }}"
	>
		<div class="abs-label" *ngIf="label">{{ label }}</div>
		<mat-form-field
			appearance="outline"
			class="outline {{ disabled ? 'disabled-mat' : '' }}"
			[floatLabel]="'never'"
		>
			<input
				type="text"
				class="font-change"
				[placeholder]="placeholder"
				matInput
				[formControl]="inputControl"
				[matAutocomplete]="auto"
			/>
			<mat-icon matSuffix>keyboard_arrow_down</mat-icon>
			<mat-autocomplete class="auto-panel" #auto="matAutocomplete">
				<div
					*ngIf="optionTypesLabel"
					class="selector-option-label-wrapper"
				>
					<span class="selector-options-label">
						{{ optionTypesLabel }}
					</span>
				</div>
				<div class="option-selector">
					<div
						class="option-type-item"
						*ngFor="let item of optionTypes"
						(click)="onOptionTypeSelected($event, item)"
					>
						<div
							class="d-flex justify-content-between align-items-center w-100"
						>
							<span class="auto-search-drp-name w-100">
								{{ item?.name }}
							</span>
							<mat-icon
								class="option-type-check-icon"
								*ngIf="isOptionTypeSelected(item)"
							>
								check
							</mat-icon>
						</div>
					</div>
				</div>
				<div
					*ngIf="optionsLabel && options.length > 0"
					class="selector-option-label-wrapper"
				>
					<span class="selector-options-label">
						{{ optionsLabel }}
					</span>
				</div>
				<ng-container *ngIf="!isOptionsLoading">
					<mat-option
						*ngFor="let item of filteredOptions | async"
						[value]="item"
						(click)="onOptionSelected($event, item)"
					>
						<div class="d-flex justify-content-between">
							<span class="auto-search-drp-name w-100">
								{{ item?.name }}
							</span>
						</div>
					</mat-option>
					<mat-option
						style="height: 0 !important"
						*ngIf="options.length <= 0"
					></mat-option>
				</ng-container>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</ng-container>
