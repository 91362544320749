import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { ShortNumberPipe } from 'src/app/shared/pipes/shortnumber-pipe';
import { GenericChartDataModel } from '../models/chart.model';

import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppChart } from '../chart';

@Component({
	selector: 'app-generic-pie-graph',
	templateUrl: './generic-pie-graph.component.html',
	styleUrls: ['./generic-pie-graph.component.scss']
})
export class GenericPieGraphComponent extends AppChart implements OnInit {
	@Input() public chartId: string = 'default-chart-id';
	@Input() public chartData: GenericChartDataModel;
	@Input() public chartHeight?: string = '100%';
	@Input() public chartWidth?: string = '100%';
	@Input() public chartXAxisLabel?: string;
	@Input() public chartYAxisLabel?: string;
	@Input() public chartXAxisCallback?: Function;
	@Input() public chartYAxisCallback?: Function;

	public donutChart?: Chart;

	private resizeSubject$: Subject<void> = new Subject<void>();
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(shortNumberPipe: ShortNumberPipe) {
		super(shortNumberPipe);
	}

	ngOnInit(): void {
		this.resizeSubject$
			.pipe(debounceTime(200), takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.generateChart(this.chartData);
			});
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.resizeSubject$.next();
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
		this.generateChart(this.chartData);
	}

	override generateChart(data: GenericChartDataModel): void {
		const generatedChartData = {
			type: 'doughnut',
			labels: data.labels,
			datasets: data.datasets.map(dataset => ({
				...dataset,
				data: dataset.data,
				type: 'doughnut' as const
			}))
		};
		const chartExist = Chart.getChart(this.chartId); // <canvas> id
		if (chartExist !== undefined) chartExist.destroy();

		this.donutChart = new Chart(this.chartId, {
			type: 'doughnut',
			data: generatedChartData,
			options: {
				responsive: true,
				aspectRatio: 2,
				plugins: {
					legend: {
						display: true,
						position: 'right'
					},
					tooltip: {
						callbacks: {
							label: this.tooltip
						}
					}
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.resizeSubject$.complete();
		const chartExist = Chart.getChart(this.chartId); // <canvas> id
		if (chartExist !== undefined) chartExist.destroy();
	}
}
