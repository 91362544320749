import { Component, Input } from '@angular/core';

@Component({
	selector: 'avatar',
	styles: [
		`
			img {
				display: block;
				width: 37px;
				height: 37px;
				border-radius: 90px;
			}
		`
	],
	template: `
		<img
			src="{{ url }}"
			(error)="url = 'assets/images/chat/icon-default-user.svg'"
		/>
	`,
	standalone: true
})
export class AvatarComponent {
	@Input()
	url: string;
}
